<template>
    <el-dialog class="editDialog" :append-to-body="false" :modal="false" :fullscreen="true" width="600px"
        :visible.sync="showEditDialog" :close-on-click-modal="false" :destroy-on-close="true"
        @close="$emit('update:isShow', false)" :show-close="true">

        <div slot="title" class="title">{{ options.id ? '编辑' : '新增' }}宗地</div>

        <div class="content" v-loading="loading">
            <el-form class="column3" label-width="6em" :model="form" :rules="rules" ref="form">

                <el-form-item label="镇/街" style="width:50%;" prop="townId" ref="townId">
                    <Dictionary v-model="form.townId" valKey="name" code="town"></Dictionary>
                </el-form-item>

                <el-form-item label="名称" style="width:50%;" prop="name" ref="name">
                    <el-input v-model="form.name" placeholder="请输入" @keyup.enter.native="submit"></el-input>
                </el-form-item>

                <el-form-item label="基本情况" prop="info" ref="info" style="width:100%;">
                    <!-- <el-input type="textarea" v-model="form.info" placeholder="请输入" @keyup.enter.native="submit"></el-input> -->
                    <Editor :info="form.info" @Editor-change="handleContentChange"></Editor>
                </el-form-item>

                <el-form-item label="标题图" style="width:100%;">
                    <UploadImages v-model="form.attachmentVos" />
                </el-form-item>

                <el-form-item label="内容图" style="width:100%;">
                    <!-- {{ form.landSituationVos }} -->
                    <UploadList v-model="form.landSituationVos">
                        <template slot="tableColumn">
                            <el-table-column align="center" label="图片" width="100">
                                <template slot-scope="scope">
                                    <div v-if="!scope.row.fileName">
                                        <Upload accept="image/*" :fields="fields" :multiple="true" @upload="upload($event, scope.$index, scope.row)">
                                            <div slot="button" class="UploadImageBtn">
                                                <i class="el-icon-plus add-img"></i>
                                            </div>
                                        </Upload>
                                    </div>
                                    <div v-else>
                                        <i v-if="scope.row.loading" class="el-icon-loading" style="font-size:30px;"></i>
                                        <img style="max-width:80px;max-height:80px;" v-else
                                            :src="scope.row.fileName | fileAddress" />
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="info" align="center" label="描述">
                                <template slot-scope="scope">
                                    <!-- <el-input type="textarea" v-model="scope.row.info" placeholder="请输入" @keyup.enter.native="submit"></el-input> -->
                                    <Editor :info="scope.row.info" @Editor-change="(content) => scope.row.info = content">
                                    </Editor>
                                </template>
                            </el-table-column>
                        </template>
                        <i slot="operation" slot-scope="{ scope }" class="iconfont iconerror"
                            @click="landSituationRemove(scope)"></i>
                    </UploadList>
                </el-form-item>

                <el-form-item label="证书类型" prop="zsType" ref="zsType" style="width:50%;">
                    <el-input v-model="form.zsType" placeholder="请输入" @keyup.enter.native="submit"></el-input>
                </el-form-item>

                <el-form-item label="宗地基本情况标题" style="width:50%;" class="fold_label" prop="landName" ref="landName">
                    <el-input v-model="form.landName" placeholder="请输入" @keyup.enter.native="submit"></el-input>
                </el-form-item>

                <el-form-item label="地块" style="width:100%;">
                    <el-tabs v-if="showEditDialog" type="border-card" addable @tab-add="parcelInfoAdd"
                        @tab-remove="parcelInfoRemove" v-model="activeParcelInfo">
                        <el-tab-pane v-for="(n, i) in form.parcelInfoVos" :label="n.tagLabel" :name="`${i}`"
                            :closable="form.parcelInfoVos.length > 1 && closable">
                            <template slot="label">
                                <el-input v-focus="n.isEditable" class="tabInput" v-if="n.isEditable" v-model="n.tagLabel"
                                    @blur="n.isEditable = false, closable = true" @focus="closable = false"></el-input>
                                <span v-else @dblclick="n.isEditable = true;">{{ n.tagLabel || (n.tagLabel=`地块${i+1}`) }}</span>
                            </template>
                            <el-descriptions title="" :column="2" border>

                                <el-descriptions-item>
                                    <template slot="label">批准书/用地规划许可证</template>
                                    <el-input v-model="n.pzs" placeholder="请输入" @keyup.enter.native="submit"></el-input>
                                </el-descriptions-item>

                                <el-descriptions-item>
                                    <template slot="label">用地单位</template>
                                    <el-input v-model="n.yddw" placeholder="请输入" @keyup.enter.native="submit"></el-input>
                                </el-descriptions-item>

                                <el-descriptions-item>
                                    <template slot="label">权属性质</template>
                                    <el-input v-model="n.qsxz" placeholder="请输入" @keyup.enter.native="submit"></el-input>
                                </el-descriptions-item>

                                <el-descriptions-item>
                                    <template slot="label">地块位置</template>
                                    <el-input v-model="n.dkwz" placeholder="请输入" @keyup.enter.native="submit"></el-input>
                                </el-descriptions-item>

                                <el-descriptions-item>
                                    <template slot="label">土地用途</template>
                                    <el-input v-model="n.tdyt" placeholder="请输入" @keyup.enter.native="submit"></el-input>
                                </el-descriptions-item>

                                <el-descriptions-item>
                                    <template slot="label">地块面积（㎡）</template>
                                    <el-input v-model="n.dkmj" placeholder="请输入" @keyup.enter.native="submit"></el-input>
                                </el-descriptions-item>

                                <el-descriptions-item>
                                    <template slot="label">建筑面积（㎡）</template>
                                    <el-input v-model="n.jzmj" placeholder="请输入" @keyup.enter.native="submit"></el-input>
                                </el-descriptions-item>

                                <el-descriptions-item>
                                    <template slot="label">容积率</template>
                                    <el-input v-model="n.rjl" placeholder="请输入" @keyup.enter.native="submit"></el-input>
                                </el-descriptions-item>

                                <el-descriptions-item>
                                    <template slot="label">绿地率</template>
                                    <el-input v-model="n.ldl" placeholder="请输入" @keyup.enter.native="submit"></el-input>
                                </el-descriptions-item>

                                <el-descriptions-item>
                                    <template slot="label">限高</template>
                                    <el-input v-model="n.xg" placeholder="请输入" @keyup.enter.native="submit"></el-input>
                                </el-descriptions-item>

                                <el-descriptions-item>
                                    <template slot="label">项目联系人</template>
                                    <el-input v-model="n.xmlxr" placeholder="请输入" @keyup.enter.native="submit"></el-input>
                                </el-descriptions-item>

                                <el-descriptions-item>
                                    <template slot="label">联系方式</template>
                                    <el-input v-model="n.lxfs" placeholder="请输入" @keyup.enter.native="submit"></el-input>
                                </el-descriptions-item>

                                <el-descriptions-item>
                                    <template slot="label">招标情况</template>
                                    <el-select v-model="n.zbqk" placeholder="请选择" @keyup.enter.native="submit">
                                        <el-option label="是" value="是"></el-option>
                                        <el-option label="否" value="否"></el-option>
                                    </el-select>
                                    <!-- <el-input v-model="n.zbqk" placeholder="请输入" @keyup.enter.native="submit"></el-input> -->
                                </el-descriptions-item>

                            </el-descriptions>

                        </el-tab-pane>
                    </el-tabs>
                </el-form-item>
            </el-form>
        </div>

        <div slot="footer" v-show="!loading">
            <el-button type="primary" :loading="submitLoading" @click="submit">保存</el-button>
            <el-button type="info" plain @click="$emit('update:isShow', false)">取消</el-button>
        </div>

    </el-dialog>
</template>
<script>

import mixinsForm from '@/mixins/form'
import { initTreeData } from "@/util/common";

export default {
    components: {
        Dictionary: () => import("@/components/Dictionary.vue"),
        UploadImages: () => import("@/components/upload/Images.vue"),
        UploadList: () => import("@/components/upload/List.vue"),
        Upload: () => import("@/components/upload/Upload.vue"),
        Editor: () => import("@/components/quillEditor/Editor.vue")
    },
    mixins: [mixinsForm],
    props: {
        treeselectOptions: {
            type: Array,
            default: function () {
                return []
            }
        },
    },
    data() {
        return {
            api: {},
            rules: {
                townId: [{ required: true, message: '请选择镇/街', trigger: ['blur', 'change'] }],
                name: [{ required: true, message: '请输入名称', trigger: ['blur', 'change'] }],
                landName: [{ required: true, message: '请输入宗地名称', trigger: ['blur', 'change'] }],
            },
            form: {
                parcelInfoVos: [{
                    pzs: '',
                    yddw: '',
                    qsxz: '',
                    tagLabel: ''
                }],
                landSituationVos: [],
                attachmentVos: [],
            },
            activeParcelInfo: '0',
            closable: true,
            multiple:{
                type:Boolean,
                default:false
            },
            fields:{
                type: Object,
                default:function(){
                    return {
                        fileName:'fileName',
                        fileAddress:'fileAddress'
                    }
                }
            },
            listData:[],
            dialogImageUrl: '',
            dialogVisible: false,
            disabled: false
        };
    },
    created() { },
    methods: {
        upload($event, index, row){
            $event.some((res, i)=>{
                this.listData.push(res);
            });
            this.listData.forEach((n, i)=> {
                this.form.landSituationVos.splice(index++, 1, this.listData[i])
            })
            // this.form.landSituationVos.splice(index, 1, this.listData[0])
            this.listData = []
        },
        handleContentChange(content) {
            this.form.info = content
        },
        parcelInfoAdd() {
            this.activeParcelInfo = this.form.parcelInfoVos.length + '';
            this.form.parcelInfoVos.push({
                tagLabel: '',
                isEditable: false,
            })
        },
        parcelInfoRemove(index) {
            var i = Number(index);
            var id = this.form.parcelInfoVos[i].id;
            if (id) {
                this.$confirm("确定删除该数据, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(() => {
                    this.$api['admin/landUseInfo'].deleteParcelInfo({ ids: [id] }).then(res => {

                        this.form.parcelInfoVos.splice(i, 1);
                        if (i == this.activeParcelInfo && i > this.form.parcelInfoVos.length - 1) {
                            this.activeParcelInfo = this.form.parcelInfoVos.length - 1 + '';
                        }
                        this.$forceUpdate()

                    }).catch(err => {
                        console.log(err);
                    });
                }).catch(() => {//用户取消

                });
            } else {
                this.form.parcelInfoVos.splice(i, 1);
                if (i == this.activeParcelInfo && i > this.form.parcelInfoVos.length - 1) {
                    this.activeParcelInfo = this.form.parcelInfoVos.length - 1 + '';
                }
                this.$forceUpdate()
            }

        },
        landSituationRemove(scope) {
            if (scope.row.id) {
                this.$confirm("确定删除该数据, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(() => {
                    this.$api['admin/landUseInfo'].deleteLandSituation({ ids: [scope.row.id] }).then(res => {

                        this.form.landSituationVos.splice(scope.$index, 1);

                    }).catch(err => {
                        console.log(err);
                    });
                }).catch(() => {//用户取消

                });
            } else {
                this.form.landSituationVos.splice(scope.$index, 1);
            }
        },
        init(options) {
            if (options.id) {
                this.$api['admin/landUseInfo'].getById(options.id).then(res => {

                    var form = res.data.landUseInfo;

                    res.data.parcelInfoList.sort((a, b) => {
                        if (a.tagLabel < b.tagLabel) {
                            return -1;
                        }
                        if (a.tagLabel > b.tagLabel) {
                            return 1;
                        }
                        return 0;
                    })

                    form.parcelInfoVos = res.data.parcelInfoList;
                    form.parcelInfoVos.forEach((e, i) => {
                        form.parcelInfoVos[i].isEditable = false
                    });
                    form.attachmentVos = res.data.attachments;

                    form.landSituationVos = [];

                    res.data.landSituations.some(r => {
                        form.landSituationVos.push(r.combination({
                            fileName: r.attachmentVoList[0].fileName,
                            fileAddress: r.attachmentVoList[0].fileAddress,
                            attachmentId: r.attachmentVoList[0].id,
                        }))
                    });

                    this.form = form;
                    this.activeParcelInfo = '0';
                    this.loading = false;
                }).catch(err => {
                    console.log(err);
                    this.loading = false;
                });
            } else {
                this.form = options.combination({
                    parcelInfoVos: [{
                        pzs: '',
                        yddw: '',
                        qsxz: '',
                        tagLabel: '',
                        isEditable: false,
                    }],
                    landSituationVos: [],
                    attachmentVos: [],
                });

                console.log(options.combination({
                    parcelInfoVos: [{
                        pzs: '',
                        yddw: '',
                        qsxz: '',
                    }],
                    landSituationVos: [],
                    attachmentVos: [],
                }))
                this.activeParcelInfo = '0';
                this.loading = false;
            };
        },
        submit() {

            this.$refs.form.validate((valid, obj) => {
                if (valid) {

                    var data = this.form.deepClone();

                    data.landSituationVos = [];

                    for (let i = 0; i < this.form.landSituationVos.length; i++) {

                        let item = this.form.landSituationVos[i];

                        let obj = {
                            id: item.id,
                            sort: i,
                            info: item.info,
                            attachmentVoList: [{
                                fileAddress: item.fileAddress,
                                fileName: item.fileName,
                            }],
                        };
                        if (item.id) {
                            obj.id = item.id;
                        };

                        if (item.attachmentId) {
                            obj.attachmentVoList[0].id = item.attachmentId;
                        };

                        data.landSituationVos.push(obj);

                    };
                    this.submitLoading = true;

                    this.$api['admin/landUseInfo'].save(data).then(res => {
                        this.submitLoading = false;
                        this.refresh();
                        this.$message.success("操作成功！");

                    }).catch(err => {
                        this.submitLoading = false;
                        console.log(err);
                    })
                } else {
                    this.scrollView(obj)
                };
            });
        },
    },
    directives: {
        focus: {
            bind(el, { value }) {
                if (value) {
                    // 重点注意这里 当前元素是div  所以要查到子元素中的input

                    const dom =
                        el.querySelector("input") ||
                        el.querySelector("textarea");
                    setTimeout(() => {
                        dom.focus();
                    }, 0)
                }
            }
        }
    }
};
</script>

<style scope>
.fold_label .el-form-item__label {
    line-height: 160% !important;
}
.add-img {
    font-size: 24px;
    cursor: pointer;
}
</style>
